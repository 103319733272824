<template>
  <article id="kb-mypage" class="content-section"
           style="border-top: none;border-bottom: 1px solid var(--kb-border-color);padding-bottom:20px;"
  >
    <header v-if="!isMobile" class="section-header">
      <h4 class="title" style="align-items: center;"> 우수학습자 인센티브 해당여부
        &nbsp; <span class="kb-btn kb-btn-primary kb-btn-xs" @click="tooltip = !tooltip">내용보기</span>
        <div class="tool-tip" :class="{'is-active': tooltip}">
          <div class="tool-tip-desc" >
            ※ 2024년 KB은행실무 종합과정 우수학습자 인센티브 제공<br><br>
            ① 은행장표창(8명)<br>
            &nbsp;- 성적우수: 성적 順 2~5위 해당자 4명<br>
            &nbsp;- 성적도약: 최근 2회 평균 대비 평가점수 대비 상승점수 順 2명<br>
            &nbsp;&nbsp;&nbsp; o 대상:최근 5개년(2019~2023년) 이내 최소 2회 이상 응시자<br>
            &nbsp;&nbsp;&nbsp;(단, 평가점수가 0점인 경우 응시횟수 미인정)<br>
            &nbsp;- 루키: 입행 5년 이내 직원중 영업점,본부부서 각 성적 順 1명<br><br>
            ②여행상품권(2백만원/60명)<br>
            &nbsp;- 성적우수: 표창 대상자를 제외한 성적 順 40명 내외<br>
            &nbsp;- 웰컴백: 최근 5개년(2019~2023년) 이내 은실종 미응시 직원 中 <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;표창대상자를 제외한 성적 順 10명<br>
            &nbsp;- 우수러닝: 은실종 연수 과정 전반에 걸쳐 성실히 참여한 학습자 10명<br>
            &nbsp;&nbsp;&nbsp; o e주차퀴즈, 함께하는 은실종 점수합산<br>
            &nbsp;&nbsp;&nbsp; o 동점자 발생시 본평가 성적 順으로 포상<br>
          </div>
        </div>
      </h4>
    </header>
    <div v-else  class="content-section content-section-card" style="padding-bottom: 10px;">
      <header class="section-header">
        <h4 class="title" style="justify-content: space-between">우수학습자 인센티브 해당여부
          <span class="kb-btn kb-btn-primary kb-btn-xxs" @click="tooltip = !tooltip">내용보기</span>   </h4>
      </header>
      <div class="tool-tip" style="z-index: 4000; " :style="tooltip? {'display':'block'} : {'display':'none'}" :class="{'is-active': tooltip}">
        <div class="tool-tip-desc"  >
          ※ 2024년 KB은행실무 종합과정 우수학습자 인센티브 제공<br><br>
          ① 은행장표창(8명)<br>
          &nbsp; 성적우수: 성적 順 2~5위 해당자 4명<br>
          &nbsp;- 성적도약: 최근 2회 평균 대비 평가점수 대비 상승점수 順 2명<br>
          &nbsp;&nbsp;&nbsp; o 대상:최근 5개년(2019~2023년) 이내 최소 2회 이상 응시자<br>
          &nbsp;&nbsp;&nbsp;(단, 평가점수가 0점인 경우 응시횟수 미인정)<br>
          &nbsp;- 루키: 입행 5년 이내 직원중 영업점,본부부서 각 성적 順 1명<br><br>
          ②여행상품권(2백만원/60명)<br>
          &nbsp;- 성적우수: 표창 대상자를 제외한 성적 順 40명 내외<br>
          &nbsp;- 웰컴백: 최근 5개년(2019~2023년) 이내 은실종 미응시 직원 中 표창대상자를 제외한 성적 順 10명<br>
          &nbsp;- 우수러닝: 은실종 연수 과정 전반에 걸쳐 성실히 참여한 학습자 10명<br>
          &nbsp;&nbsp;&nbsp; o e주차퀴즈, 함께하는 은실종 점수합산<br>
          &nbsp;&nbsp;&nbsp; o 동점자 발생시 본평가 성적 順으로 포상<br>
        </div>
      </div>
    </div>


    <div  v-if="!isMobile" class="certificate-status-container" style="justify-content: space-evenly; width:1200px; margin:auto;padding-top: 20px;">
      <LoadingDiv v-if="isLoading" />
      <template v-else>
        <!--  status-card-->
        <div class="status-card" :class="{ 'is-active':  bpccInfo.finjkgup !== 'L0' }"  >
          <div class="card-icon"><i class="icon-certificate-01"></i></div>
          <div class="card-content">
            <div class="content-title">은행장표창<br>(성적우수)</div>
          </div>
        </div>

        <div class="status-card" :class="{ 'is-active': bpccInfo.bpccCnt >= 2 && bpccInfo.finjkgup !== 'L0' }">
          <div class="card-icon"><i class="icon-certificate-01"></i></div>
          <div class="card-content">
            <div class="content-title">은행장표창<br>(성적도약)</div>
          </div>
        </div>
        <div class="status-card" :class="{ 'is-active':  bpccInfo.finjkgup !== 'L0' &&  bpccInfo.fidiphil >= '20200101'}">
          <div class="card-icon"><i class="icon-certificate-01"></i></div>
          <div class="card-content">
            <div class="content-title">은행장표창<br>(루키)</div>
          </div>
        </div>
        <div class="status-card is-active">
          <div class="card-icon"><i class="icon-certificate-02"></i></div>
          <div class="card-content">
            <div class="content-title">여행상품권<br>(성적우수)</div>
          </div>
        </div>
        <div class="status-card" :class="{ 'is-active':  bpccInfo.bpccCnt == 0 }" >
          <div class="card-icon "><i class="icon-certificate-02"></i></div>
          <div class="card-content">
            <div class="content-title">여행상품권<br>(웰컴백)</div>
          </div>
        </div>
        <div class="status-card is-active" >
          <div class="card-icon"><i class="icon-certificate-02"></i></div>
          <div class="card-content">
            <div class="content-title">여행상품권<br>(우수러닝)</div>
          </div>
        </div>
      </template>

    </div>

    <div v-else  class="certificate-status-container mx-1" >
      <LoadingDiv v-if="isLoading" />
    <div v-else class="status-wrap">
      <!--  status-card-->
      <div class="status-card" :class="{ 'is-active':  bpccInfo.finjkgup !== 'L0' }"  >
        <div class="card-icon"><i class="icon-certificate-01"></i></div>
        <div class="card-content">
          <div class="content-title font-body12">은행장<br>표창<br>성적우수</div>
        </div>
      </div>

      <div class="status-card" :class="{ 'is-active': bpccInfo.bpccCnt >= 2 && bpccInfo.finjkgup !== 'L0' }">
        <div class="card-icon"><i class="icon-certificate-01"></i></div>
        <div class="card-content">
          <div class="content-title font-body12">은행장<br>표창<br>성적도약</div>
        </div>
      </div>
      <div class="status-card" :class="{ 'is-active':  bpccInfo.finjkgup !== 'L0' &&  bpccInfo.fidiphil >= '20200101'}">
        <div class="card-icon"><i class="icon-certificate-01"></i></div>
        <div class="card-content">
          <div class="content-title font-body12">은행장<br>표창<br>루키</div>
        </div>
      </div>
      <div class="status-card is-active">
        <div class="card-icon"><i class="icon-certificate-02"></i></div>
        <div class="card-content">
          <div class="content-title font-body12">여행<br>상품권<br>성적우수</div>
        </div>
      </div>
      <div class="status-card" :class="{ 'is-active':  bpccInfo.bpccCnt == 0 }" >
        <div class="card-icon "><i class="icon-certificate-02"></i></div>
        <div class="card-content">
          <div class="content-title font-body12">여행<br>상품권<br>웰컴백</div>
        </div>
      </div>
      <div class="status-card is-active" >
        <div class="card-icon"><i class="icon-certificate-02"></i></div>
        <div class="card-content">
          <div class="content-title font-body12">여행<br>상품권<br>우수러닝</div>
        </div>
      </div>
      <!--  status-card-->
    </div>
    </div>
  </article>
</template>

<script>

import {computed, ref} from 'vue';
import navigationUtils from "@/assets/js/navigationUtils";
import {ACT_GET_CRSE_BPCC_LIST} from "@/store/modules/course/course";
import {getItem, lengthCheck} from "@/assets/js/util";
import {useStore} from "vuex";
import LoadingDiv from '@/components/common/LoadingDiv.vue';

export default {
  name: "BpccInfo",
  components: { LoadingDiv},
  setup(props) {
    const store = useStore();
    const licenseCodes = computed(() => props.items.map(x => x.lcsnCd));
    const tooltip = ref(false);
    const salesLicenses = [
      {
        name: '펀드',
        necessaries: [
          ['WR2B', 'WR0X', 'WR2M', 'WR6Z', 'WR2Z'],
          ['WR7B', 'WR3D', 'WR2O', 'WR3O', 'WR6Z', 'WR2Z', 'WR7D', 'WR3B'],
          ['WR7C', 'WR3C', 'WR2N', 'WR6Z',  'WR2Z', 'WR7D', 'WR3B' ]
        ]
      },
      {
        name: '파생',
        necessaries: [
          ['WR7A', 'WR7G', 'WR3P', 'WR2X', 'WR2J', 'WR86', 'WR47', 'WR36']
        ]
      },
      {
        name: '생명',
        necessaries: [
          ['WR72', 'WR74']
        ]
      },
      {
        name: '손해',
        necessaries: [
          ['WR73', 'WR75']
        ]
      },
      {
        name: '제3',
        necessaries: [
          ['WR77', 'WR78']
        ]
      },
      {
        name: '변액',
        necessaries: [
          ['WR76', 'WR9D']
        ]
      }
    ];
    const isLoading = ref(false);
    const bpccInfo = ref({});
    const getBpccInfo = ()=> {
      isLoading.value = true;
      store.dispatch(`course/${ACT_GET_CRSE_BPCC_LIST}`, {}).then((res) => {
        if (lengthCheck(res)) {
          bpccInfo.value = getItem(res);
          // console.log(bpccInfo.value);
          // console.log(bpccInfo.value.finjkgup);
        } else {
          bpccInfo.value = {};
        }
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const isGet = (necessaries) => {
      let count = 0;
      for(let i = 0; i < necessaries.length; i++){
        // 내 자격증 중에서 있을 해당 데이터가 있을 경우
        for(let j = 0; j < licenseCodes.value.length; j++){
          if(necessaries[i].includes(licenseCodes.value[j])){
            count++;
            break;
          }
        }
      }
      return (necessaries.length === count);
    }

    getBpccInfo();

    return {
      bpccInfo,
      tooltip,
      isLoading,
      salesLicenses,
      isGet,
      isMobile: navigationUtils.any()
    }
  }
}
</script>
